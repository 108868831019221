import { connect } from "react-redux";
import { setMeta, setPickerPeriod } from "../../actions/actionsMetadata";
import DefaultLayout from "./DefaultLayout";

// CONNECT PROPS AND DISPATCHERS
const mapStateToProps = state => {
  return {
    user: state.userReducer,
    metadata: state.metadataReducer
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setMeta: (clientSlug, startDate, endDate, datePickerPeriod, initialDate) =>
      dispatch(
        setMeta(clientSlug, startDate, endDate, datePickerPeriod, initialDate)
      ),
    setPickerPeriod: period => dispatch(setPickerPeriod(period))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DefaultLayout);
