import { connect } from "react-redux";
import ChartDonut from "./ChartDonut";

const mapStateToProps = state => {
  return {
    clientSlug: state.metadataReducer.clientSlug
  };
};

export default connect(mapStateToProps, null)(ChartDonut);
