import React from 'react';
import { ButtonToolbar, ButtonGroup, Button } from 'reactstrap';

interface TimePeriodSwitcherProps {
  changeDisplayPeriod: Function;
  active: 'day' | 'week' | 'month';
}

const TimePeriodSwitcher = (props: TimePeriodSwitcherProps) => {
  const { changeDisplayPeriod = () => void 0, active = 'day' } = props;

  return (
    <ButtonToolbar className="float-right" aria-label="Period button groups">
      <ButtonGroup aria-label="Toggle period">
        <Button
          data-test="changeDisplayPeriod-day"
          onClick={() => {
            changeDisplayPeriod('day');
          }}
          color="outline-primary"
          active={active === 'day'}
        >
          Day
        </Button>
        <Button
          data-test="changeDisplayPeriod-week"
          onClick={() => {
            changeDisplayPeriod('week');
          }}
          color="outline-primary"
          active={active === 'week'}
        >
          Week
        </Button>
        <Button
          data-test="changeDisplayPeriod-month"
          onClick={() => {
            changeDisplayPeriod('month');
          }}
          color="outline-primary"
          active={active === 'month'}
        >
          Month
        </Button>
      </ButtonGroup>
    </ButtonToolbar>
  );
};

export default TimePeriodSwitcher;
