import store from "store2";

export const initialState = {
  status: {
    loading: false,
    error: null,
    done: false
  },
  data: null
};

let stateFromStore = initialState;
if (store("user")) {
  stateFromStore = store("user");
}

const userReducer = (state = stateFromStore, action) => {
  switch (action.type) {
    case "CLEAR_USERDATA":
      return {
        ...initialState
      };

    case "LOG_IN_PENDING":
      return {
        ...state,
        status: {
          loading: true,
          error: null,
          done: false
        }
      };

    case "LOG_IN_FULFILLED":
      const updatedState = {
        ...state,
        status: {
          loading: false,
          error: null,
          done: true
        },
        data: action.payload.data
      };
      store("user", updatedState);
      return updatedState;

    case "LOG_IN_REJECTED":
      return {
        ...state,
        status: {
          loading: false,
          error: action.payload.response,
          done: false
        }
      };
    default:
      return state;
  }
};

export default userReducer;
