import React from "react";
import { Router, Route, Switch } from "react-router-dom";
import "./App.scss";
import { includes as _includes } from "lodash";
import { refreshAccessToken } from "./actions/actionsAuth";
import { Login, Page404, Page500, Unauthorized } from "./views/Pages";
import { PrivateRoute, DefaultLayout } from "./components";
import history from "./history";

interface AppProps {
  refreshAccessToken: Function;
}

const App: React.FC<AppProps> = props => {
  React.useEffect(() => {
    if (!_includes(window.location.href, "/login")) {
      refreshAccessToken();
    }
    window.addEventListener("error", detectUpdate, true);

    return () => window.removeEventListener("error", detectUpdate, true);
  }, []);

  const detectUpdate = e => {
    if (_includes(e.target.src, ".chunk.js")) {
      window.location.reload();
    }
  };

  return (
    <Router history={history}>
      <Switch>
        <Route exact path="/login" name="Login Page" component={Login} />
        <Route exact path="/404" name="Page 404" component={Page404} />
        <Route exact path="/500" name="Page 500" component={Page500} />
        <Route
          exact
          path="/unauthorized"
          name="Unauthorized"
          component={Unauthorized}
        />
        <PrivateRoute path="/" name="Home" component={DefaultLayout} />
      </Switch>
    </Router>
  );
};

export default App;
