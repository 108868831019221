export const toggleBoard = () => {
  return {
    type: 'TOGGLE_BOARD',
  };
};

export const toggleDevMode = () => {
  return {
    type: 'TOGGLE_DEV_MODE',
  };
};
