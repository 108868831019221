import { connect } from 'react-redux';
import { toggleBoard } from '../../actions/actionsUi';
import LoaderGame from './LoaderGame';

const mapStateToProps = state => {
  return {
    ui: state.uiReducer,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    toggleBoard: () => dispatch(toggleBoard()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoaderGame);
