import styled from "styled-components";
const Wrap = styled.div`
  position: absolute;
  left: 50%;
  transform: translate(-50%, -30%);
  top: 30%;
`;
const Loader = styled.div`
  z-index: 20;
  display: inline-block;
  width: 64px;
  height: 64px;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;

  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 51px;
    height: 51px;
    margin: 6px;
    border: 6px solid #006bdf;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #006bdf transparent transparent transparent;
  }

  div:nth-child(1) {
    animation-delay: -0.45s;
  }
  div:nth-child(2) {
    animation-delay: -0.3s;
  }
  div:nth-child(3) {
    animation-delay: -0.15s;
  }
`;

export default { Wrap, Loader };
