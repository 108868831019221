export const colors = {
  error: "#D0021B",
  success: "#5EB246",
  gray: "#9B9B9B",
  darkGray: "#2F323A",
  primary: "#0b2641",
  baseLight: "#ffffff",
  active: "#25B3EB",
  basedark: "#E3692A",

  c1: "#25B3EB",
  c2: "#0b2641",
  c3: "#386a2a",
  t1: "#25B3EB",
  t2: "#9d68a7",
  t3: "#f3a536",
  t4: "#1d7fcb",
  t5: "#21c4c5",
  t6: "#8ed131",
  t7: "#1F87C4"
};

export const measurements = {
  borderRadius: "4px"
};

export const range = [
  "#1683fb",
  "#1375E1",
  "#1068C8",
  "#0D5BAF",
  "#0A4E96",
  "#07417D",
  "#043464",
  "#02274b"
];
