import styled, { css } from 'styled-components';

export const Container = styled.div`
  margin-top: 2em;
  position: relative;
  @media screen and (max-width: 480px) {
    margin-top: 3em;
  }
`;

export const Title = styled.h3`
  text-align: center;
  padding-bottom: 1em;
  color: #232323;
`;
export const Toggle = styled.div`
  width: 25px;
  height: 50px;
  border-bottom-left-radius: 50px;
  border-top-left-radius: 50px;
  background-color: white;
  position: absolute;
  left: -25px;
  top: 50%;
  box-shadow: -2px 0 2px rgba(0, 0, 0, 0.3);
  transform: translateY(-50%);
  span {
    padding: 18px 7px;
    transition: transform 0.5s ease-in-out;
  }
  &:hover {
    background-color: #006bdf;
    cursor: pointer;
    color: white;
    span {
      transform: rotate(180deg);
      transition: transform 0.5s ease-in-out;
    }
  }
`;
const active = css`
  margin-right: 0px;
  transition: margin-right 0.5s;
`;
const hide = css`
  margin-right: -480px;
  transition: margin-right 0.5s;
  ${Toggle} {
    span {
      transform: rotate(0deg);
    }
  }
  @media screen and (max-width: 480px) {
    .hide {
      margin-right: -365px;
    }
  }
`;
export const Style = styled.div`
  width: 480px;
  position: fixed;
  right: 0;
  margin-right: -550px;
  height: 500px;
  background: white;
  top: 10px;
  border-radius: 10px 0 0 10px;
  margin-top: 9em;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
  z-index: 2;
  ${props => (props.active ? active : '')}
  ${props => (props.hide ? hide : '')}
  @media screen and (max-width:990px) {
    top: 60px;
  }
  @media screen and (max-width: 480px) {
    width: 80%;
    top: 55px;
  }
`;

export default {
  Style,
  Toggle,
  Title,
  Container,
};
