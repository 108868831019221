import styled, { css } from 'styled-components';

const DebugId = styled.span`
  position: absolute;
  z-index: 99;
  background-color: #ffced2;
  border: 1px solid black;
  padding: 1px;
  border-radius: 5px;
  opacity: 0.5;
  transform: translate(3px, 3px);
  font-size: 12px;
  font-weight: bold;
  color: black;

  ${props => {
    if (props.section) {
      return css`
        transform: translate(-17px, 37px);
        background-color: #ffe071;
      `;
    }
  }}
`;

export default { DebugId };
