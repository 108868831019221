import { setMeta, setPickerPeriod } from '../../actions/actionsMetadata';
import { connect } from 'react-redux';
import CustomDatePicker from './CustomDatePicker';

const mapStateToProps = state => {
  return {
    metadata: state.metadataReducer,
    user: state.userReducer,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setMeta: (clientSlug, startDate, endDate) =>
      dispatch(setMeta(clientSlug, startDate, endDate)),
    setPickerPeriod: period => dispatch(setPickerPeriod(period)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomDatePicker);
