import React, { Component } from "react";
import { DayPickerRangeController } from "react-dates";
import moment from "moment";
import Styled from "./CustomDatePicker.styles";
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap";
import cx from "classnames";
import { Icon } from "@savewatersavemoney/swsm-ui";
class CustomDatePicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      focusedInput: null,
      initialDate: this.props.metadata.initialDate,
      startDate: this.props.metadata.startDate,
      endDate:
        this.props.metadata.endDate ||
        moment()
          .subtract(1, "days")
          .format("YYYY-MM-DD"),
      dropdownOpen: false,
      mobile: false
    };
  }

  componentDidMount() {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions);
  }

  componentDidUpdate(prevProps) {
    // if slug changed update also startDate and endDate
    if (prevProps.metadata.clientSlug !== this.props.metadata.clientSlug) {
      const currentSite = this.getCurrentSiteDetails();
      this.setState({
        endDate: this.props.metadata.endDate,
        startDate: this.props.metadata.startDate,
        initialDate: currentSite.initialDate
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  updateDimensions = () => {
    if (window.innerWidth < 660) {
      this.setState({ mobile: true });
    } else {
      this.setState({ mobile: false });
    }
  };

  getCurrentSiteDetails = () => {
    return this.props.user.data.sites.find(
      item => item.slug === this.props.metadata.clientSlug
    );
  };

  handleDateFilterChange = e => {
    const { value } = e.target;
    const currentSite = this.getCurrentSiteDetails();
    this.props.setPickerPeriod(value); //call action

    switch (value) {
      case "today":
        this.setState({
          startDate: moment().format("YYYY-MM-DD"),
          endDate: moment().format("YYYY-MM-DD")
        });
        return;

      case "everything":
        this.setState({
          startDate: moment(currentSite.initialDate).format("YYYY-MM-DD"),
          endDate: moment()
            .endOf("day")
            .format("YYYY-MM-DD")
        });
        return;

      case "yesterday":
        this.setState({
          startDate: moment()
            .endOf("day")
            .subtract(1, "days")
            .format("YYYY-MM-DD"),
          endDate: moment()
            .endOf("day")
            .subtract(1, "days")
            .format("YYYY-MM-DD")
        });
        return;

      case "this_week":
        this.setState({
          startDate: moment()
            .startOf("week")
            .format("YYYY-MM-DD"),
          endDate: moment()
            .endOf("day")
            .format("YYYY-MM-DD")
        });
        return;

      case "last_week":
        this.setState({
          startDate: moment()
            .subtract(1, "weeks")
            .startOf("week")
            .format("YYYY-MM-DD"),
          endDate: moment()
            .subtract(1, "weeks")
            .endOf("week")
            .format("YYYY-MM-DD")
        });
        return;

      case "this_month":
        this.setState({
          startDate: moment()
            .startOf("month")
            .format("YYYY-MM-DD"),
          endDate: moment()
            .endOf("day")
            .format("YYYY-MM-DD")
        });
        return;

      case "last_month":
        this.setState({
          startDate: moment()
            .subtract(1, "month")
            .startOf("month")
            .format("YYYY-MM-DD"),
          endDate: moment()
            .subtract(1, "month")
            .endOf("month")
            .format("YYYY-MM-DD")
        });
        return;

      default:
        return false;
    }
  };

  dropdownOpen = focus => {
    this.setState({
      dropdownOpen: true,
      focusedInput: focus
    });
  };

  dropdownClose = () => {
    this.setState({
      dropdownOpen: false,
      focusedInput: null
    });
  };

  onClickApply = () => {
    this.props.setMeta(false, this.state.startDate, this.state.endDate);
    this.dropdownClose();
  };

  onClickCancel = () => {
    this.dropdownClose();
  };

  onFocusChange = focusedInput => {
    this.setState({
      // Force the focusedInput to always be truthy so that dates are always selectable
      focusedInput: !focusedInput ? "startDate" : focusedInput
    });
  };

  onDatesChange = (startDate, endDate) => {
    this.setState({
      startDate: startDate.format("YYYY-MM-DD"),
      endDate: endDate
        ? endDate.format("YYYY-MM-DD")
        : startDate.format("YYYY-MM-DD")
    });

    if (
      (this.state.startDate !== startDate.format("YYYY-MM-DD") ||
        (endDate && this.state.endDate !== endDate.format("YYYY-MM-DD"))) &&
      this.props.metadata.datePickerPeriod !== "custom"
    ) {
      this.props.setPickerPeriod("custom");
    }
  };

  clickStartDate = () => {
    this.onFocusChange("startDate");

    if (this.state.dropdownOpen && this.state.focusedInput === "startDate") {
      this.dropdownClose();
    } else {
      this.dropdownOpen("startDate");
    }
  };

  clickEndDate = () => {
    this.onFocusChange("endDate");

    if (this.state.dropdownOpen && this.state.focusedInput === "endDate") {
      this.dropdownClose();
    } else {
      this.dropdownOpen("endDate");
    }
  };

  dateRange = day => {
    const { initialDate } = this.props.metadata;
    return !day.isBetween(initialDate, moment().endOf("day"));
  };

  render() {
    const { focusedInput, startDate, endDate } = this.state;

    return (
      <Styled.Wrapper>
        <Dropdown isOpen={this.state.dropdownOpen} toggle={() => {}}>
          <DropdownToggle
            tag="div"
            data-toggle="dropdown"
            aria-expanded={this.state.dropdownOpen}
          >
            <Styled.Default>Default date range:</Styled.Default>
            <div className="display">
              <Icon icon="calendar" size={30} />
              <div
                onClick={this.clickStartDate}
                className={cx("field", {
                  active: this.state.focusedInput === "startDate"
                })}
              >
                {startDate}
              </div>
              <Styled.Separate>-</Styled.Separate>
              <div
                onClick={this.clickEndDate}
                className={cx("field", {
                  active: this.state.focusedInput === "endDate"
                })}
              >
                {endDate}
                <span className="fa fa-chevron-down ml-1 mr-1" />
              </div>
            </div>
          </DropdownToggle>

          <DropdownMenu>
            <div>
              <DayPickerRangeController
                onDatesChange={({ startDate, endDate }) =>
                  this.onDatesChange(startDate, endDate)
                } // PropTypes.func.isRequired,
                onFocusChange={focusedInput => this.onFocusChange(focusedInput)} // PropTypes.func.isRequired,
                focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                startDate={moment(startDate)}
                endDate={endDate ? moment(endDate) : null}
                isOutsideRange={this.dateRange}
                minimumNights={0}
                numberOfMonths={this.state.mobile ? 1 : 2}
                hideKeyboardShortcutsPanel={true}
              />
              <Styled.Info>
                <Row>
                  <Col sm="12" md="6">
                    <div className="form-group mb-0">
                      <select
                        value={this.props.metadata.datePickerPeriod}
                        onChange={this.handleDateFilterChange}
                        className="form-control"
                        name="filterSelectDate"
                        id="filterSelectDate"
                      >
                        <option value="custom">Custom</option>
                        <option value="today">Today</option>
                        <option value="yesterday">Yesterday</option>
                        <option value="this_week">This week</option>
                        <option value="last_week">Last week</option>
                        <option value="this_month">This month</option>
                        <option value="last_month">Last month</option>
                        <option value="everything">Everything</option>
                      </select>
                    </div>
                  </Col>

                  <Col sm="12" md="6" className="text-right">
                    <button
                      className="btn btn-primary mr-2 mb-2"
                      onClick={this.onClickApply}
                    >
                      Apply
                    </button>
                    <button
                      className="btn btn-primary mb-2"
                      onClick={this.onClickCancel}
                    >
                      Cancel
                    </button>
                  </Col>
                </Row>
              </Styled.Info>
            </div>
          </DropdownMenu>
        </Dropdown>
      </Styled.Wrapper>
    );
  }
}

export default CustomDatePicker;
