import { combineReducers } from 'redux';
import store from 'store2';

import userReducer from './user';
import metadataReducer from './metadata';
import uiReducer from './ui';
import genericPageReducer from './genericPage';

const rootReducer = (state, action) => {
  if (action.type === 'CLEAR_USERDATA') {
    store.clear();
    state = undefined;
  }
  return appReducer(state, action);
};

const appReducer = combineReducers({
  userReducer,
  genericPageReducer,
  metadataReducer,
  uiReducer,
});

export default rootReducer;
