import store from "store2";
import { get as _get, includes as _includes } from "lodash";
import axios from "axios";
import JWTDecode from "jwt-decode";
import { logOut, refreshAccessToken } from "../actions/actionsAuth";
import reduxStore from "../configureStore";

// TODO: that might be problem in IE
// const axiosInstance = axios.create({
//   headers: {
//     Pragma: 'no-cache',
//   },
// });

export const isLoggedIn = () => {
  const userFromLocalStorage = store("user");
  return _get(userFromLocalStorage, "data.accessToken", false);
};

export const setupInterceptors = () => {
  const updateToken = accessToken => {
    store.transact("user", obj => {
      obj.data.accessToken = accessToken;
    });
  };

  let isFetchingToken = false;
  let tokenSubscribers: any = [];

  function subscribeTokenRefresh(cb) {
    tokenSubscribers.push(cb);
  }

  function onTokenRefreshed(errRefreshing, token) {
    tokenSubscribers.map(cb => cb(errRefreshing, token));
  }

  function forceLogout() {
    isFetchingToken = false;
    reduxStore.dispatch<any>(logOut());
  }

  axios.interceptors.request.use(
    reqConfig => {
      const accessToken = _get(store("user"), "data.accessToken", false);
      const refreshToken = _get(store("user"), "data.refreshToken", false);

      // console.log('interceptors: reqConfig');
      // console.log(reqConfig);

      // Todo: make sure that token will be not send if any random api will be called from react app

      reqConfig.headers.authorization = "Bearer " + accessToken;

      if (
        _includes(reqConfig.url, "/logout") ||
        _includes(reqConfig.url, "/token")
      ) {
        reqConfig.headers["x-refresh-token"] = refreshToken;
      }

      return reqConfig;
    },
    err => Promise.reject(err)
  );

  axios.interceptors.response.use(undefined, err => {
    // console.log(err.response);

    if (err.response === undefined) {
      // console.log('no response');
      return Promise.reject(err);
    } else if (_includes(err.response.config.url, "/login")) {
      // console.log('login (prevent loop) return error');
      return Promise.reject(err);
    } else if (err.response.status === 403) {
      // The request is for something forbidden. Authorization will not help.
      forceLogout();
      return Promise.reject(err);
    } else if (err.response.status === 404) {
      return Promise.reject(err);
    } else if (err.response.status !== 401) {
      return Promise.reject(err); //only 401 unauthorized
    }
    // console.log('401 status');

    // The parameter to this message gives a specification of
    // authorization schemes which are acceptable.
    // The client should retry the request with a suitable Authorization header.

    // Todo: fix loop of mailformed if its 401 and token is mailformed
    // its going into infinite loop of requests

    if (!isFetchingToken) {
      const refreshToken = _get(store("user"), "data.refreshToken", false);

      isFetchingToken = true;

      if (!refreshToken) {
        // console.log("No token found in localStorage");
        forceLogout();
        return Promise.reject(err);
      }
      try {
        const isRefreshTokenExpired =
          JWTDecode(refreshToken).exp < Date.now() / 1000;

        if (isRefreshTokenExpired) {
          // console.log("isRefreshTokenExpired");
          forceLogout();
          return Promise.reject(err);
        }
      } catch (error) {
        // console.log("Can't decode token");
        forceLogout();
        return Promise.reject(err);
      }

      refreshAccessToken()
        .then(newAccessToken => {
          isFetchingToken = false;
          if (newAccessToken === null) {
            // console.log("newAccessToken is empty");
            forceLogout();
          } else {
            onTokenRefreshed(null, newAccessToken);
            tokenSubscribers = [];
            updateToken(newAccessToken);
          }
        })
        .catch(() => {
          // console.log("refreshAccessToken ERROR");
          onTokenRefreshed(new Error("Unable to refresh access token"), null);
          tokenSubscribers = [];
          forceLogout();
        });
    }

    return new Promise((resolve, reject) => {
      subscribeTokenRefresh((errRefreshing, newToken) => {
        if (errRefreshing) {
          // console.log('errRefreshing');
          return reject(errRefreshing);
        }
        // console.log('resolve again');
        err.config.headers.authorization = newToken;
        return resolve(axios(err.config));
      });
    });
  });
};
