import React from "react";
import { Col } from "reactstrap";
import * as _ from "lodash";
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  Legend
} from "recharts";
import {
  TooltipSimple,
  TicksBold,
  chartDataTransform,
  setChartBarHeight
} from "../../helpers";
import { NothingToDisplay, DebugId } from "../index";
import { ChartData } from "../../interfaces";
import { colors } from "../../assets/variables";

interface ChartBar2Props {
  id: number;
  chartData: ChartData;
  theme?: string;
  chartKey?: string;
}
// TODO: refactor to handle multiple datasets
const ChartBar: React.FC<ChartBar2Props> = props => {
  const { chartData, theme = "c1", id, chartKey } = props;
  const data = chartDataTransform(chartData);

  const totals = _.sum(chartData.totals);

  const themes = theme.split(",");
  const renderBars = () => {
    return _.map(chartData.datasets, (bar, index) => {
      return (
        <Bar
          key={`dataset_${index}`}
          name={bar.label}
          dataKey={`dataset_${index}`}
          fill={colors[_.get(themes, index, theme)]}
          radius={[0, 5, 5, 0]}
        />
      );
    });
  };
  const height = setChartBarHeight(data, chartData);
  return (
    <Col sm="12" chartKey={chartKey}>
      <DebugId id={id} />

      {data.length > 0 && totals > 0 ? (
        <ResponsiveContainer height={height} width="100%">
          <BarChart
            data={data}
            layout="vertical"
            margin={{ top: 0, right: 0, bottom: 0, left: 80 }}
            barCategoryGap={15}
          >
            <CartesianGrid horizontal={false} strokeWidth={0.5} stroke="#ddd" />
            <XAxis stroke="#bbb" type="number" />
            <YAxis
              stroke="#bbb"
              type="category"
              dataKey="name"
              tick={TicksBold}
              width={chartKey === "gwf_total_product_orders" ? 280 : 60}
            />
            <Tooltip
              cursor={{
                fill: "#F5F5F5"
              }}
              content={TooltipSimple}
            />
            {renderBars()}
            <Legend />
          </BarChart>
        </ResponsiveContainer>
      ) : (
        <NothingToDisplay type="chart" />
      )}
    </Col>
  );
};

export default ChartBar;
