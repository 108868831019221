import React from 'react';
import { Card, CardBody, CardHeader, Col, CardTitle } from 'reactstrap';
import { list, Icon } from '../../../components';
import * as _ from 'lodash';

interface IconListProps {
  someProp?: any;
}

const IconList: React.FC<IconListProps> = props => {
  const iconList = list();

  const renderIcon = () => {
    return _.map(iconList.icons, icon => {
      return (
        <span
          key={icon}
          title={icon}
          style={{ padding: '20px', lineHeight: '7em' }}
        >
          <Icon size="4em" color="#444" icon={icon} />
          {icon}
        </span>
      );
    });
  };

  const renderIconUi = () => {
    return _.map(iconList.iconsUi, icon => {
      return (
        <span
          key={`ui-${icon}`}
          title={icon}
          style={{ padding: '20px', lineHeight: '7em' }}
        >
          <Icon size="4em" color="#444" icon={`ui-${icon}`} />
          ui-{icon}
        </span>
      );
    });
  };

  return (
    <Col sm="12">
      <Card>
        <CardHeader>
          <CardTitle className="mb-2 mt-2">
            <h5>Icons</h5>
          </CardTitle>
        </CardHeader>
        <CardBody>
          <CardBody>{renderIcon()}</CardBody>
        </CardBody>
      </Card>
      <Card>
        <CardHeader>
          <CardTitle className="mb-2 mt-2">
            <h5>Icons UI</h5>
          </CardTitle>
        </CardHeader>
        <CardBody>
          <CardBody>{renderIconUi()}</CardBody>
        </CardBody>
      </Card>
    </Col>
  );
};

export default IconList;
