import "react-app-polyfill/ie11"; // For IE 11 support
import "core-js/stable";
import "./polyfill";
import React from "react";
import { render } from "react-dom";
import App from "./App";
import { Provider } from "react-redux";
import store from "./configureStore";
import * as serviceWorker from "./serviceWorker";
import SentryBoundary from "./SentryBoundary";
import "./i18n";
import { setupInterceptors } from "./helpers";
import WebFont from "webfontloader";

WebFont.load({
  google: {
    families: ["Open Sans:400,600,700", "sans-serif"]
  }
});

setupInterceptors();

const rootEl = document.getElementById("root");

const renderApp = () =>
  render(
    <Provider store={store}>
      <SentryBoundary>
        <App />
      </SentryBoundary>
    </Provider>,
    rootEl
  );

if (process.env.NODE_ENV !== "production" && module.hot) {
  module.hot.accept("./App", renderApp);
}

renderApp();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
