import React from 'react';
import { ChartData } from '../../interfaces';
import { InfoBoxCircle } from '../index';
import { Row, Col } from 'reactstrap';
import * as _ from 'lodash';
import { flattenChartData } from '../../helpers';

interface ChartBrowsersProps {
  title: string;
  theme?: string;
  chartData: ChartData;
  id: number;
}

const ChartMultiCircle: React.FC<ChartBrowsersProps> = props => {
  const { chartData } = props;
  const browsers = flattenChartData(chartData);

  const data = _.orderBy(browsers.data, ['value'], ['desc']);

  const iconFinder = name => {
    switch (name) {
    case 'Chrome':
      return 'chrome';
    case 'mobile':
      return 'mobile';
    case 'desktop':
      return 'desktop';
    case 'tablet':
      return 'tablet';
    case 'Safari':
      return 'safari';
    case 'Android Webview':
      return 'android';
    case 'Safari (in-app)':
      return 'safari';
    case 'Internet Explorer':
      return 'internet-explorer';
    case 'Edge':
      return 'internet-explorer';
    case 'Amazon Silk':
      return 'amazon';
    case 'Firefox':
      return 'firefox';
    case '\'Mozilla':
      return 'firefox';
    case 'Mozilla Compatible Agent':
      return 'firefox';
    case 'Android Browser':
      return 'android';
    default:
      return 'globe';
    }
  };

  return (
    <Row>
      {_.map(data, (box, index) => {
        const title: string =
          box.label.charAt(0).toUpperCase() + box.label.slice(1);

        return (
          <Col key={index} xs="12" sm="6" md="4">
            <InfoBoxCircle
              id={345}
              value={box.value}
              total={browsers.total}
              title={title}
              icon={`ui-${iconFinder(box.label)}`}
              theme={`t${(index % 6) + 1}`}
            />
          </Col>
        );
      })}
    </Row>
  );
};

export default ChartMultiCircle;
