import React from "react";
import { Row, Col } from "reactstrap";
import Styled from "./DownloadFile.style";
import { Icon } from "@savewatersavemoney/swsm-ui";
import _ from "lodash";
import { getDownloadCsvData } from "../../actions/actionsDownloadCsv";
export interface DownloadFileProps {
  data: any;
  clientSlug?: string;
}

const DownloadFile = (props: DownloadFileProps) => {
  const { data, clientSlug } = props;

  const renderData = () => {
    return _.map(data, (el, index) => {
      return (
        <Row key={index} className="justify-content-center wrapper">
          <Col xs="12" sm="8" md="8">
            <p>{el.name}</p>
          </Col>
          <Col
            xs="12"
            sm="4"
            md="4"
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Styled.DownloadButton
              onClick={() => getDownloadCsvData(el.links, clientSlug)}
            >
              <div>Download CSV</div>
              <Icon icon="download" size={20} colorPrimary="#fff" />
            </Styled.DownloadButton>
          </Col>
        </Row>
      );
    });
  };
  return <Styled.Wrap className="animated fadeIn ">{renderData()}</Styled.Wrap>;
};

export default DownloadFile;
