import React from "react";
import Styled from "./ChartDonut.style";
import {
  PieTooltip,
  CustomPieLegend,
  UseColors,
  chartDataTransform,
  chartDataColors,
  prettifyIfNumber
} from "../../helpers";
import { ResponsiveContainer, Tooltip, PieChart, Pie } from "recharts";
import { Icon } from "@savewatersavemoney/swsm-ui";
import { ChartData } from "../../interfaces";
import { colors } from "../../assets/variables";
import * as _ from "lodash";
import { CardWrap } from "../../components";

interface ChartDonut {
  id: number;
  chartData: ChartData;
  theme?: string;
  icon: string;
  title: string;
  clientSlug?: string;
  chartKey: string;
  description?: string;
}

const ChartDonut = (props: ChartDonut) => {
  const {
    chartData,
    icon,
    title,
    theme,
    id,
    clientSlug,
    chartKey,
    description
  } = props;

  const data = chartDataTransform(chartData);
  const dataColors = chartDataColors(chartData, theme);
  const color = colors[dataColors[0]];
  const totals = _.sum(chartData.totals);

  if (!data || data.length < 1 || totals === 0) {
    return (
      <CardWrap id={id} title={title} icon={icon} description={description}>
        <Styled.ChartPie>
          <div
            data-test="no-data-info"
            className="mt-3 mb-5 pr-1 pl-1 text-center text-muted"
            style={{ margin: "auto" }}
          >
            There is no data available for your chosen date range.
          </div>
        </Styled.ChartPie>
      </CardWrap>
    );
  }

  const renderTotals = () => {
    return (
      <div className="text-center mt-3">
        <h5>Total:</h5>
        <h4>
          {prettifyIfNumber(_.sum(chartData.totals))}{" "}
          {_.get(chartData, "datasets[0].label", "")}
        </h4>
      </div>
    );
  };

  const renderChart = () => {
    return (
      <PieChart
        height={200}
        width={200}
        margin={{
          top: 0,
          right: 0,
          left: 0,
          bottom: 0
        }}
      >
        <Pie
          data={data}
          dataKey="dataset_0"
          outerRadius="100%"
          fill={colors[chartData.datasets[0].theme]}
          paddingAngle={0}
          startAngle={90}
          endAngle={-270}
          isAnimationActive={false}
          innerRadius="75%"
        >
          {UseColors({ data, color })}
        </Pie>
        <Tooltip content={<PieTooltip />} />
      </PieChart>
    );
  };

  return (
    <CardWrap id={id} title={title} icon={icon} description={description}>
      <Styled.ChartPie chartKey={chartKey}>
        <Styled.Legend className="legend mt-4">
          {CustomPieLegend({ data, color, totals, clientSlug })}
        </Styled.Legend>
        <Styled.Square>
          <Styled.Content>
            <Icon size={100} icon="droplet" />
            {renderTotals()}
          </Styled.Content>
          <ResponsiveContainer aspect={1} width="100%">
            {renderChart()}
          </ResponsiveContainer>
        </Styled.Square>
      </Styled.ChartPie>
    </CardWrap>
  );
};

export default ChartDonut;
