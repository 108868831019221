import { connect } from 'react-redux';
import { logIn } from '../../../actions/actionsAuth';
import Login from './Login';

const mapStateToProps = state => {
  return {
    user: state.userReducer,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    logIn: credentials => dispatch(logIn(credentials, ownProps.history)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login);
