import styled from "styled-components";
import { colors } from "../assets/variables";
const PieLegendWrap = styled.ul`
  padding-inline-start: 0;
  max-width: 400px;
  width: 100%;
  list-style-type: none;
`;

const PieLegendLi = styled.li`
  width: 100%;
  display: flex;
  padding-bottom: 16px;
  align-items: center;
`;

const PieLegendDot = styled.span`
  height: 22px;
  width: 22px;
  background-color: ${props => props.color};
  border-radius: 20px;
  min-width: 10px;
  margin-right: 10px;
  flex: 0 1 22px;
`;

const PieLegendValue = styled.span`
  margin-left: auto;
  min-width: 70px;
  text-align: right;
  font-size: 16px;
`;
const PieLegendText = styled.span`
  font-size: 16px;
  display: flex;
  text-align: left;
  flex: 1;
`;
const DonwloadButton = styled.button`
  width: auto;
  height: 32px;
  border-radius: 10px;
  border: 2px solid ${colors.active};
  margin-left: 16px;
  background-color: white;
  background-color: ${colors.active};
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  div {
    color: white;
    font-size: 12px;
    font-weight: 600;
    padding: 1px 6px;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
export default {
  PieLegendWrap,
  PieLegendLi,
  PieLegendDot,
  PieLegendValue,
  PieLegendText,
  DonwloadButton
};
