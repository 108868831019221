import store from "store2";
import moment from "moment";

export const initialState = {
  initialDate: null,
  startDate: null,
  endDate: null,
  clientSlug: null,
  datePickerPeriod: "custom"
};

let stateFromStore = initialState;
if (store("metadata")) {
  stateFromStore = store("metadata");
}

const metadataReducer = (state = stateFromStore, action) => {
  switch (action.type) {
    case "CLEAR_USERDATA":
      return {
        ...initialState
      };

    case "LOG_IN_FULFILLED":
      const [firstSite] = action.payload.data.sites;

      const dateMonthBefore = moment()
        .subtract(2, "month")
        .format("YYYY-MM-DD");

      const lessThanMonthData = moment(dateMonthBefore).isBefore(
        firstSite.initialDate
      );

      const initialMetaState = {
        ...state,
        initialDate: firstSite.initialDate,
        startDate: lessThanMonthData ? firstSite.initialDate : dateMonthBefore,
        clientSlug: firstSite.slug,
        endDate: moment()
          .endOf("day")
          .format("YYYY-MM-DD")
      };

      store("metadata", initialMetaState);
      return initialMetaState;

    case "SET_META":
      const updatedState = {
        ...state,
        clientSlug: action.clientSlug ? action.clientSlug : state.clientSlug,
        startDate: action.startDate ? action.startDate : state.startDate,
        endDate: action.endDate ? action.endDate : state.endDate,
        datePickerPeriod: action.datePickerPeriod
          ? action.datePickerPeriod
          : state.datePickerPeriod,
        initialDate: action.initialDate ? action.initialDate : state.initialDate
      };

      store("metadata", updatedState);
      return updatedState;

    case "SET_PICKER_PERIOD":
      return {
        ...state,
        datePickerPeriod: action.period
      };

    default:
      return state;
  }
};

export default metadataReducer;
