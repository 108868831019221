import React from "react";
import Board from "./Board";
import { Col } from "reactstrap";
import Styled from "./MemoryGame.style";

interface MemoryGameProps {
  ui?: any;
}

const MemoryGame: React.FC<MemoryGameProps> = props => {
  const { ui } = props;

  const [active, setActive] = React.useState(false);

  const click = () => {
    setActive(!active);
  };

  return (
    <Styled.Style active={ui.boardActive} hide={active}>
      <Styled.Container>
        <Styled.Toggle onClick={click}>
          <span className="fa fa-chevron-left" />
        </Styled.Toggle>
        <Styled.Title>You think you can finish the game?</Styled.Title>
        <Col lg="10" md={{ size: 10, offset: 1 }} sm={{ size: 10, offset: 1 }}>
          <Board />
        </Col>
      </Styled.Container>
    </Styled.Style>
  );
};

export default MemoryGame;
