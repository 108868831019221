import React from "react";
import { Card, CardBody } from "reactstrap";
import { Icon } from "@savewatersavemoney/swsm-ui";
import { prettifyIfNumber } from "../../helpers";

import Styled from "./InfoBox.styles";
export interface InfoBoxProps {
  id?: number | string;
  icon: string;
  dataTest?: string;
  loading?: boolean;
  value?: number;
  subtitle?: string;
  theme?: string;
  layout?: "horizontal" | "vertical";
  valueType?: "time" | "number" | "currency";
  chartKey: string;
}

const InfoBox = (props: InfoBoxProps) => {
  const {
    subtitle,
    value = 0,
    valueType = "number",
    layout = "vertical",
    icon,
    chartKey
  } = props;

  const typeSuffix = (value, valueType) => {
    switch (valueType) {
      case "time":
        return `${value.toFixed(0)} mins`;
      case "currency":
        return `£${prettifyIfNumber(value)}`;
      default:
        return `${prettifyIfNumber(value)}`;
    }
  };

  return (
    <Card className="flex-fill" chartKey={chartKey}>
      <CardBody>
        <Styled.Wrapper layout={layout}>
          <Styled.Icon layout={layout} style={{ backgroundColor: "#d7f1ff" }}>
            <Icon icon={icon} size={30} />
            {/* <Icon icon={icon} size={30} /> */}
          </Styled.Icon>
          <Styled.Text layout={layout}>
            <Styled.Subtitle layout={layout}>{subtitle}</Styled.Subtitle>
            {value && (
              <Styled.Title layout={layout}>
                {typeSuffix(value, valueType)}
              </Styled.Title>
            )}
          </Styled.Text>
        </Styled.Wrapper>
      </CardBody>
    </Card>
  );
};

export default InfoBox;
