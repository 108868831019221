import React from 'react';
import { CircleProgress } from '../index';
import { Spring } from 'react-spring/renderprops.cjs';
import Styled from './InfoBoxCircle.styles';
interface InfoBoxCircleProps {
  id: number;
  value: number;
  total: number;
  title: string;
  icon: string;
  theme: string;
}

const InfoBoxCircle = (props: InfoBoxCircleProps) => {
  const { icon = 'star', theme = 't1', title, total, value } = props;

  function getProgressCircle() {
    return (
      <Spring from={{ number: 0 }} to={{ number: value }}>
        {props => (
          <CircleProgress
            icon={icon}
            theme={theme}
            value={props.number}
            total={total}
          />
        )}
      </Spring>
    );
  }

  return (
    <Styled.Wrapper>
      <Styled.Title>
        <h3>{title}</h3>
      </Styled.Title>
      <Styled.ProgressBar>
        {getProgressCircle()}
        <Styled.Subtitle>unique visitors</Styled.Subtitle>
      </Styled.ProgressBar>
    </Styled.Wrapper>
  );
};

export default InfoBoxCircle;
