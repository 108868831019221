import styled from "styled-components";
import { colors } from "../../assets/variables";

const Wrap = styled.div`
  .wrapper {
    padding: 16px;
    &:nth-child(2n-1) {
      background-color: #d7f1ff;
    }
  }
  p {
    font-size: 18px;
    margin: 0;
  }
`;

const DownloadButton = styled.button`
  width: auto;
  height: 32px;
  border-radius: 10px;
  border: 2px solid ${colors.active};
  margin-left: 16px;
  background-color: white;
  background-color: ${colors.active};
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  div {
    color: white;
    font-size: 12px;
    font-weight: 600;
    padding: 1px 6px;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
export default { Wrap, DownloadButton };
