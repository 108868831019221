import axios from 'axios';
import { getEndpointURL } from '../helpers';
import JWTDecode from 'jwt-decode';
import { logOut } from '../actions/actionsAuth';
import { get as _get } from 'lodash';
import store from 'store2';

export const toConstName = (string: string): string => {
  if (!string) {
    return '';
  }
  return string.toUpperCase().replace('-', '_');
};

export const getEndpointData = (meta, endpoint) => {
  const refreshToken = _get(store('user'), 'data.refreshToken', false);
  const url = `api/2.0/${endpoint}`;

  if (!refreshToken) {
    return logOut();
  }

  try {
    const isRefreshTokenExpired =
      JWTDecode(refreshToken).exp < Date.now() / 1000;
    if (isRefreshTokenExpired)
      return logOut();
  } catch (error) {
    return logOut();
  }

  return dispatch => {
    dispatch(getEndpointDataPending(endpoint));
    return axios
      .get(getEndpointURL(url), {
        params: {
          ...meta,
        },
      })
      .then(resp => {
        dispatch(getEndpointDataFulfilled(resp, endpoint));
      })
      .catch(error => {
        dispatch(getEndpointDataRejected(error, endpoint));
      });
  };
};

export const getEndpointDataPending = endpointName => {
  return {
    endpointName,
    type: `GET_${toConstName(endpointName)}_DATA_PENDING`,
  };
};
export const getEndpointDataFulfilled = (resp, endpointName) => {
  return {
    type: `GET_${toConstName(endpointName)}_DATA_FULFILLED`,
    endpointName,
    payload: resp,
  };
};
export const getEndpointDataRejected = (resp, endpointName) => {
  return {
    type: `GET_${toConstName(endpointName)}_DATA_REJECTED`,
    endpointName,
    payload: resp,
  };
};
