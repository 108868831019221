import styled, { css } from "styled-components";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex: 1 1 auto !important;
  flex-direction: ${props =>
    props.layout === "horizontal" ? "row" : "column"};
  @media screen and (max-width: 900px) {
    flex-direction: column;
  }
`;

const Icon = styled.div`
  width: 50px;
  height: 50px;
  min-width: 50px;
  border-radius: 10px;
  margin-right: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  ${props =>
    props.layout === "vertical"
      ? css`
          margin: 0 auto 10px;
        `
      : ""};
  @media screen and (max-width: 900px) {
    margin: 0 auto 10px;
  }
`;
const Text = styled.div`
  display: flex;
  flex: 1;
  overflow-wrap: anywhere;
  hyphens: auto;
  width: 100%;
  flex-direction: column;
  ${props =>
    props.layout === "vertical"
      ? css`
          align-items: center;
        `
      : ""};
  @media screen and (max-width: 900px) {
    align-items: center;
  }
`;

const Subtitle = styled.div`
  display: flex;
  color: #0b2641;
  font-size: 16px;
  ${props =>
    props.layout === "horizontal"
      ? css`
          font-weight: 500;
          line-height: 1.2;
          font-size: 14px;
          color: #0b2641;
        `
      : ""};
  @media screen and (max-width: 900px) {
    text-align: center;
    margin-bottom: 6px;
    min-height: 34px;
  }
`;

const Title = styled.div`
  display: flex;
  font-size: 18px;
  font-weight: 500;
  ${props =>
    props.layout === "horizontal"
      ? css`
          font-size: 24px;
          color: #0b2641;
          font-weight: 600;
        `
      : ""};
  @media screen and (max-width: 900px) {
    text-align: center;
  }
`;

export default {
  Icon,
  Text,
  Title,
  Subtitle,
  Wrapper
};
