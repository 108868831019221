import React from 'react';
import IcomoonReact, { iconList } from 'icomoon-react';
import iconSet from '@savewatersavemoney/iconfont/selection.json';
import iconSetUI from './selection-ui.json';
import { IconProps } from '../../interfaces';

export const list = () => {
  return {
    icons: iconList(iconSet),
    iconsUi: iconList(iconSetUI),
  };
};

const Icon = (props: IconProps) => {
  const { color = '#fff', size = '100%', icon } = props;

  if (icon.substr(0, 3) === 'ui-') {
    return (
      <IcomoonReact
        iconSet={iconSetUI}
        color={color}
        size={size}
        icon={icon.substr(3)}
      />
    );
  }
  return (
    <IcomoonReact iconSet={iconSet} color={color} size={size} icon={icon} />
  );
};

export default Icon;
