/// <reference types="webpack-env" />
import {
  applyMiddleware,
  compose,
  createStore,
  Store,
  Dispatch,
  Action,
  AnyAction
} from "redux";
import thunkMiddleware from "redux-thunk";
import promise from "redux-promise-middleware";
// import monitorReducersEnhancer from './enhancers/monitorReducers';
// import loggerMiddleware from './middleware/logger';
import rootReducer from "./reducers";

export interface ConnectedReduxProps<A extends Action = AnyAction> {
  dispatch: Dispatch<A>;
}

let window: any;

export const configureStore = (initialState: any): Store => {
  const middlewares = [thunkMiddleware, promise];
  // if (process.env.NODE_ENV === 'development') {
  // middlewares.push(loggerMiddleware);
  // }

  const middlewareEnhancer = applyMiddleware(...middlewares);

  const enhancers = [middlewareEnhancer];

  const composedEnhancers =
    typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__(...enhancers)
      : compose(...enhancers);

  const store = createStore(rootReducer, initialState, composedEnhancers);

  if (process.env.NODE_ENV !== "production" && module.hot) {
    module.hot.accept("./reducers", () => store.replaceReducer(rootReducer));
  }

  return store;
};

const store = configureStore(undefined);

export default store;
