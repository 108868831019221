import { connect } from "react-redux";
import DownloadFile from "./DownloadFile";

const mapStateToProps = state => {
  return {
    clientSlug: state.metadataReducer.clientSlug
  };
};

export default connect(mapStateToProps, null)(DownloadFile);
