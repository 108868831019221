import React from "react";
import Styled from "./ChartPie.styles";
import {
  PieTooltip,
  RenderCustomizedLabel,
  CustomPieLegend,
  UseColors,
  chartDataTransform,
  chartDataColors
} from "../../helpers";
import { ResponsiveContainer, Tooltip, PieChart, Pie } from "recharts";
import Icon from "../Icon/Icon";
import { ChartData } from "../../interfaces";
import { colors } from "../../assets/variables";
import * as _ from "lodash";

interface ChartPie {
  id?: number;
  chartData: ChartData;
  theme?: string;
  icon?: string;
  title: string;
  donut?: boolean;
  clientSlug?: string;
}

const ChartPie = (props: ChartPie) => {
  const { chartData, icon, title, donut, theme, clientSlug } = props;

  const data = chartDataTransform(chartData);
  const dataColors = chartDataColors(chartData, theme);
  const color = colors[dataColors[0]];
  const totals = _.sum(chartData.totals);

  if (!data || data.length < 1 || totals === 0) {
    return (
      <Styled.ChartPie>
        {icon && (
          <Styled.Icon>
            <Icon color="#dcdcdc" size="30%" icon={icon} />
          </Styled.Icon>
        )}
        <Styled.Title>{title}</Styled.Title>
        <div className="mt-3 mb-3 pr-1 pl-1 text-center text-muted h4">
          <Icon color="#dcdcdc" size={180} icon="ui-pie-chart" />
        </div>
        <div
          data-test="no-data-info"
          className="mt-3 mb-5 pr-1 pl-1 text-center text-muted"
        >
          There is no data available for your chosen date range.
        </div>
      </Styled.ChartPie>
    );
  }

  const renderChart = () => {
    const donutProps = donut && {
      innerRadius: "65%"
    };
    return (
      <PieChart
        height={400}
        width={400}
        margin={{
          top: 0,
          right: 0,
          left: 0,
          bottom: 0
        }}
      >
        <Pie
          data={data}
          dataKey="dataset_0"
          outerRadius="100%"
          fill={colors[chartData.datasets[0].theme]}
          paddingAngle={0}
          label={props => RenderCustomizedLabel({ ...props, donut })}
          startAngle={90}
          endAngle={-270}
          isAnimationActive={false}
          {...donutProps}
        >
          {UseColors({ data, color })}
        </Pie>
        <Tooltip content={<PieTooltip />} />
      </PieChart>
    );
  };

  return (
    <Styled.ChartPie>
      <Styled.Square>
        <ResponsiveContainer aspect={1} width="100%">
          {renderChart()}
        </ResponsiveContainer>
      </Styled.Square>
      <div className="legend mt-4">
        {CustomPieLegend({ data, color, totals, clientSlug })}
      </div>
    </Styled.ChartPie>
  );
};

export default ChartPie;
