import React from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';
import Icon from '../Icon';

interface NothingToDisplay {
  title?: string;
  subtitle?: string;
  icon?: string;
  type?: string;
}

const NothingToDisplay = props => {
  let { title, subtitle, icon, type } = props;

  if (type === 'chart') {
    icon = 'ui-pie-chart';
    title = 'No data';
    subtitle = 'There is no data available for your chosen date range.';
  }

  return (
    <div className="animated fadeIn">
      <Row className="justify-content-center">
        <Col md="6">
          <Card>
            <CardBody>
              <span className="clearfix">
                <span className="float-left mr-4">
                  <Icon icon={icon || 'ui-warning'} color="#b5b5b5" size={50} />
                </span>
                <h4>{title || 'Opsss... We have a problem!'}</h4>
                <p className="text-muted float-left mt-3">
                  {subtitle ||
                    'There is no data for the page you are looking for or is temporarily unavailable.'}
                </p>
              </span>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default NothingToDisplay;
