import React from "react";
import Styled from "./DebugId.style";
import * as _ from "lodash";

interface DebugIdProps {
  id?: number;
  section?: boolean;
  devMode?: boolean;
}

const DebugId: React.FC<DebugIdProps> = props => {
  const showdebug =
    _.get(window, "se_config.debug", false) ||
    process.env.REACT_APP_DEBUG === "true" ||
    props.devMode;

  if (showdebug) {
    return <Styled.DebugId section={props.section}>{props.id}</Styled.DebugId>;
  }
  return null;
};

export default DebugId;
