import React, { CSSProperties } from "react";
import { capitalizeFirstLetter } from "../helpers";
import { colorRange } from "./global";
import { range } from "../assets/variables";
import chroma from "chroma-js";
import { prettifyIfNumber } from "./formatters";
import { Cell } from "recharts";
import { Trans } from "react-i18next";
import Styled from "./customChartElements.style";
import * as _ from "lodash";
import moment from "moment";

export const TicksDateRotated = props => {
  const { x, y, payload, period } = props;

  const tickText = () => {
    switch (period) {
      case "month":
        return moment(payload.value).format("MMM YYYY");
      default:
        return moment(payload.value).format("DD MMM YYYY");
    }
  };

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        transform="rotate(-35)"
        x={0}
        y={7}
        dy={5}
        textAnchor="end"
        fill="#666"
      >
        {tickText()}
      </text>
    </g>
  );
};

export const ConversionTooltip = e => {
  if (e.payload[0]) {
    const conversion = Math.floor(
      (e.payload[0].value / e.payload[1].value) * 100
    );

    const wrapStyle = {
      backgroundColor: "#fff",
      padding: "10px",
      border: "1px solid #c8ced3",
      borderRadius: "2px",
      boxShadow: "-7px 8px 16px rgba(0, 0, 0, 0.10)",
      minWidth: "150px"
    };

    const datasets = e.payload.map((el, index) => {
      return (
        <div key={index}>
          <span
            style={{
              display: "inline-block",
              height: "10px",
              width: "10px",
              backgroundColor: el.color,
              borderRadius: "20px",
              marginRight: "6px"
            }}
          />{" "}
          {el.value} {el.name}
        </div>
      );
    });

    return (
      <div style={wrapStyle}>
        {datasets}
        <hr className="mt-4" />
        <div>
          <span className="text-muted ">Conversion</span>
          <span className="float-right">{conversion}%</span>
        </div>
      </div>
    );
  }
};

export const ticks = label => {
  return moment(label).format("DD MMM YYYY");
};

export const TicksBold = props => {
  const { x, y, payload } = props;
  if (payload && payload.value) {
    return (
      <g transform={`translate(${x},${y})`}>
        <text fontWeight="bold" x={0} y={0} dy={5} textAnchor="end" fill="#666">
          <Trans>{capitalizeFirstLetter(payload.value)}</Trans>
        </text>
      </g>
    );
  }
};

export function TooltipDateWithPeriod(e, period, sort = false) {
  if (e.payload && e.payload[0]) {
    const wrapStyle = {
      backgroundColor: "#fff",
      padding: "10px",
      border: "1px solid #c8ced3",
      borderRadius: "2px",
      boxShadow: "-7px 8px 16px rgba(0, 0, 0, 0.10)",
      minWidth: "180px"
    };

    let datasetsArray = e.payload.map(el => {
      return {
        color: el.color,
        value: el.value,
        name: el.name
      };
    });

    if (sort) {
      datasetsArray = _.orderBy(datasetsArray, ["value"], ["desc"]);
    }

    const datasets = datasetsArray.map((el, index) => {
      return (
        <div key={index}>
          <span
            style={{
              display: "inline-block",
              height: "10px",
              width: "10px",
              backgroundColor: el.color,
              borderRadius: "20px",
              marginRight: "6px"
            }}
          />{" "}
          {el.name} {prettifyIfNumber(el.value)}
        </div>
      );
    });

    const dateLabel = e.label;

    const renderLabel = () => {
      switch (period) {
        case "month":
          return moment(dateLabel).format("MMM YYYY");
        default:
          return moment(dateLabel).format("DD MMM YYYY");
      }
    };

    return (
      <div style={wrapStyle}>
        {datasets}
        <hr className="mt-3" />
        <div>
          <span className="text-muted ">Date</span>
          <span className="float-right">{renderLabel()}</span>
        </div>
      </div>
    );
  }
}

export const RenderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  donut
}) => {
  const RADIAN = Math.PI / 180;
  const radius =
    innerRadius + (outerRadius - innerRadius) * (donut ? 0.5 : 0.8);
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      style={{
        pointerEvents: "none",
        fontSize: "13px",
        fontWeight: "bolder",
        textShadow: "rgba(0, 0, 0, 0.6) 2px 2px 4px"
      }}
      x={x}
      y={y}
      fill="white"
      textAnchor="middle"
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

export const TooltipSimple = e => {
  if (e.payload && e.payload[0]) {
    const wrapStyle = {
      backgroundColor: "#fff",
      padding: "10px",
      border: "1px solid #c8ced3",
      borderRadius: "2px",
      boxShadow: "-7px 8px 16px rgba(0, 0, 0, 0.10)",
      minWidth: "180px"
    };

    const datasets = e.payload.map((el, index) => {
      return (
        <div key={index}>
          <span
            style={{
              display: "inline-block",
              height: "10px",
              width: "10px",
              backgroundColor: el.color,
              borderRadius: "20px",
              marginRight: "6px"
            }}
          />{" "}
          {prettifyIfNumber(el.value)} {el.name}{" "}
        </div>
      );
    });

    return <div style={wrapStyle}>{datasets}</div>;
  }
};

export const PieTooltip = props => {
  const { payload } = props;

  const UseColors = () => _.get(props, "payload[0].payload.fill");

  const wrapStyle: CSSProperties = {
    backgroundColor: "#fff",
    padding: "10px",
    border: "1px solid #c8ced3",
    borderRadius: "2px",
    boxShadow: "-7px 8px 16px rgba(0, 0, 0, 0.10)",
    maxWidth: "300px"
  };
  const liStyle: CSSProperties = {
    width: "100%",
    display: "flex",
    flexWrap: "nowrap",
    flexDirection: "row"
  };

  const dotStyle: CSSProperties = {
    height: "10px",
    width: "10px",
    backgroundColor: UseColors(),
    borderRadius: "20px",
    minWidth: "10px",
    marginTop: "5px",
    marginRight: "10px"
  };

  const textStyle: CSSProperties = {
    width: "100%",
    flexGrow: 2,
    marginRight: "10px"
  };

  const datasets = payload.map((el, index) => {
    return (
      <li style={liStyle} key={index}>
        <span style={dotStyle} />
        <span style={textStyle}>
          <Trans>{capitalizeFirstLetter(el.name)}</Trans>:
        </span>{" "}
        {prettifyIfNumber(el.value)}
      </li>
    );
  });
  return <ul style={wrapStyle}>{datasets}</ul>;
};

const generateColorsArray = (color: string, length: number): Array<string> => {
  return chroma
    .scale([color, chroma(color).brighten(1)])
    .mode("lch")
    .colors(length);
};

export const UseColors = ({ data, color }) => {
  if (color) {
    return data.map((entry, index) => (
      <Cell
        key="cell"
        fill={colorRange(index, generateColorsArray(color, data.length))}
      />
    ));
  }
  return data.map((entry, index) => (
    <Cell key="cell" fill={range[index % range.length]} />
  ));
};

export const CustomPieLegend = ({ data, color, totals, clientSlug }) => {
  const colorArray = () => {
    if (color) {
      return generateColorsArray(color, data.length);
    }
    return range;
  };

  if (data && data.length) {
    const dotColor = (index: number) => {
      return colorRange(index, colorArray());
    };

    return (
      <Styled.PieLegendWrap>
        {data.map((el, index) => {
          return (
            <Styled.PieLegendLi key={index}>
              <Styled.PieLegendDot color={dotColor(index)} />
              <Styled.PieLegendText>
                <Trans>{capitalizeFirstLetter(el.name)}</Trans>
              </Styled.PieLegendText>
              <Styled.PieLegendValue
                title={`${((el.dataset_0 / totals) * 100).toFixed(0)}%`}
              >
                {prettifyIfNumber(el.dataset_0)}
              </Styled.PieLegendValue>
            </Styled.PieLegendLi>
          );
        })}
      </Styled.PieLegendWrap>
    );
  }
};
