import { connect } from 'react-redux';
import { getEndpointData } from '../../actions/actionsShared';
import GenericPage from './GenericPage';

// CONNECT PROPS AND DISPATCHERS
const mapStateToProps = state => {
  return {
    genericPage: state.genericPageReducer,
    metadata: state.metadataReducer,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getEndpointData: (meta, endpoint) =>
      dispatch(getEndpointData(meta, endpoint)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GenericPage);
