import styled, { keyframes } from 'styled-components';

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Loading = styled.span`
  box-sizing: border-box;
  display: block;
  width: 1em;
  height: 1em;
  border-top: 0.1em solid #fff;
  border-radius: 50%;
  animation: ${spin} 1.2s infinite;
  display: inline-block;
  margin: 0 10px 0 0;
`;

export default Loading;
