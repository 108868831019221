import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { Container, Col, Row } from "reactstrap";
import { get as _get, forEach as _forEach } from "lodash";
import {
  AppBreadcrumb,
  AppHeader,
  AppSidebar,
  AppSidebarFooter,
  AppSidebarForm,
  AppSidebarHeader,
  AppSidebarNav
} from "@coreui/react";
// sidebar nav config
import moment from "moment";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { CustomDatePicker, MemoryGame, DefaultHeader } from "..";
import IconList from "../../views/Debug/IconList";
import GenericPage from "../../views/GenericPage";
import Styled from "./DefaultLayout.style";

// interface DefaultLayoutProps {
//   user?: any;
//   metadata?: any;
//   setMeta?: Function;
// }

const DefaultLayout = props => {
  const clientOnChange = e => {
    const { value: clientSlug } = e.target;

    const currentSite = props.user.data.sites.find(
      item => item.slug === clientSlug
    );
    const { initialDate } = currentSite;

    if (props.metadata && props.setMeta) {
      const requireReset = moment(props.metadata.startDate).isBefore(
        initialDate
      );

      props.setMeta(
        clientSlug,
        requireReset ? initialDate : undefined,
        requireReset
          ? moment()
              .startOf("day")
              .format("YYYY-MM-DD")
          : undefined,
        "custom",
        initialDate
      );
    }
  };

  const clientDropdown = () => {
    const sites = _get(props, "user.data.sites", false);

    if (sites.length > 0) {
      if (props.metadata.clientSlug === null && props.setMeta) {
        props.setMeta(
          sites[0].slug,
          sites[0].initialDate,
          moment()
            .endOf("day")
            .format("YYYY-MM-DD")
        );
      }

      if (sites.length > 1) {
        return (
          <AppSidebarHeader>
            <div className="form-group">
              <label htmlFor="selectSlug" className="">
                Select Client:
              </label>
              <select
                value={props.metadata.clientSlug || undefined}
                onChange={clientOnChange}
                className="form-control"
                name="selectSlug"
                id="selectSlug"
              >
                {sites.map(site => {
                  return (
                    <option key={site.name} value={site.slug}>
                      {site.name}
                    </option>
                  );
                })}
              </select>
            </div>
          </AppSidebarHeader>
        );
      }
    }
  };

  const getCurrentUserPermissions = () => {
    const { clientSlug } = props.metadata;
    const currentSite = props.user.data.sites.find(
      item => item.slug === clientSlug
    );
    return _get(currentSite, "permissions", []);
  };

  // const restrictNav = navigation => {
  //   const permissions = getCurrentUserPermissions();
  //   const { items } = navigation;
  //   const showIfAllowed = el => {
  //     if (
  //       el.hasOwnProperty("title") &&
  //       _intersection(permissions, el.showFor).length > 0
  //     ) {
  //       return true;
  //     }
  //     if (el.url) {
  //       return _includes(permissions, el.url.substring(1));
  //     }
  //     return false;
  //   };
  //   if (items.length > 0) {
  //     const newNav = items.filter(el => showIfAllowed(el));
  //     return {
  //       items: newNav
  //     };
  //   }
  //   return {
  //     items: []
  //   };
  // };

  const permissionRoutes = () => {
    const permissions = getCurrentUserPermissions();
    let pRoutes = [];
    _forEach(permissions, section => {
      _forEach(section.children, el => {
        pRoutes.push({
          path: `/${el.path}`,
          name: `${section.sectionTitle}: ${el.name}`,
          component: GenericPage
        });
      });
    });
    return pRoutes;
  };

  const permissionsNav = () => {
    const permissions = getCurrentUserPermissions();
    let nav = [];
    _forEach(permissions, el => {
      nav.push({ title: true, name: el.sectionTitle });
      _forEach(el.children, child => {
        nav.push({
          name: child.name,
          url: `/${child.path}`,
          class: `menu-${child.name}`
        });
      });
    });
    return {
      items: nav
    };
  };

  const pRoutes = permissionRoutes();
  const pNavigation = permissionsNav();
  const homepage = _get(pRoutes, "[0].path", "/unauthorized");

  return (
    <div className="app">
      <AppHeader fixed>
        <DefaultHeader />
      </AppHeader>
      <div className="app-body">
        <AppSidebar display="lg">
          {clientDropdown()}
          <AppSidebarForm />
          <AppSidebarNav navConfig={pNavigation} {...props} />
          <AppSidebarFooter />
        </AppSidebar>
        <Styled.Wrap className="main">
          <Styled.BreadcrumbsWrap>
            <Row>
              <Col
                lg="6"
                xl="6"
                sm="6"
                md="6"
                xs="12"
                className="d-flex align-items-center"
              >
                <AppBreadcrumb appRoutes={pRoutes} />
              </Col>

              <Col lg="6" xl="6" sm="6" xs="12" md="6" className="datepicker">
                <CustomDatePicker />
              </Col>
            </Row>
          </Styled.BreadcrumbsWrap>

          <Container fluid id="main-wrap">
            <Switch>
              <Route
                exact
                path="/icons"
                name="Icon List"
                component={IconList}
              />
              {pRoutes.map((route, idx) => {
                return route.component ? (
                  <Route
                    key={idx}
                    path={route.path}
                    exact={route.exact}
                    name={route.name}
                    render={props => <route.component {...props} />}
                  />
                ) : null;
              })}
              <Redirect from="/" to={homepage} />
            </Switch>
            <MemoryGame />
          </Container>
        </Styled.Wrap>
        {/* <AppAside fixed>
          <DefaultAside />
        </AppAside> */}
      </div>
    </div>
  );
};

export default DefaultLayout;
