import styled from "styled-components";
const Wrap = styled.div`
  background-color: #e5e5e5;
  font-family: "Open Sans" sans-serif;
`;
const BreadcrumbsWrap = styled.div`
  margin: 16px;
  border-radius: 10px;
  background: white;
  padding: 16px;
  nav {
    ol {
      margin-bottom: 0px;
      border-bottom: 0px;
      .breadcrumb-item.active {
        color: black;
      }
      li {
        font-size: 20px;
        font-weight: 600;
      }
    }
  }
  .datepicker {
    justify-content: flex-end;
    display: flex;
  }
`;

export default { Wrap, BreadcrumbsWrap };
