export const initialState = {
  boardActive: false,
  playButtonVisible: true,
  devMode: false,
};

const uiReducer = (state = initialState, action) => {
  switch (action.type) {
  case 'TOGGLE_DEV_MODE':
    return {
      ...state,
      devMode: !state.devMode,
    };
  case 'TOGGLE_BOARD':
    return {
      ...state,
      playButtonVisible: false,
      boardActive: !state.boardActive,
    };
  default:
    return state;
  }
};

export default uiReducer;
