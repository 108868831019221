import React from "react";

import {
  ChartBar,
  ChartLine,
  ChartPie,
  ChartDonut,
  InfoBoxDetailed,
  InfoBoxCircle,
  InfoBox,
  ChartMultiCircle,
  DebugId,
  CardWrap,
  DownloadFile
} from "../../components";

import { Col, Card, CardBody } from "reactstrap";
import { ChartData } from "../../interfaces";

interface RenderComponentProps {
  icon: string;
  id: number;
  size: "small" | "medium" | "large" | "full";
  text: string;
  theme: string;
  title: string;
  total: number;
  valueText: string;
  totalText: string;
  type: string;
  value: number;
  valueType: "time" | "number" | "currency";
  chartData: ChartData;
  chartkey: string;
  description: string;
  data: any;
}

const RenderComponent: React.FC<RenderComponentProps> = props => {
  const {
    title,
    text,
    value,
    total,
    valueText,
    totalText,
    icon,
    id,
    size,
    theme,
    chartkey,
    type,
    valueType,
    chartData,
    description,
    data
  } = props;

  const sizeProps = size => {
    switch (size) {
      case "small":
        return {
          sm: 6,
          md: 3
        };
      case "semi-medium":
        return {
          sm: 4,
          md: 4
        };
      case "medium":
        return {
          sm: 6,
          md: 6,
          xl: 6
        };
      case "large":
        return {
          sm: 6,
          md: 6
        };
      default:
        return {
          sm: 12
        };
    }
  };

  switch (type) {
    case "info-box-detailed":
      return (
        <Col md={6}>
          <DebugId id={id} />
          <CardWrap id={id} title={title} icon={icon}>
            <InfoBoxDetailed
              id={id}
              theme={theme}
              total={total}
              value={value}
              text={text}
              totalText={totalText}
              valueText={valueText}
              chartKey={chartkey}
            />
          </CardWrap>
        </Col>
      );
    case "download-file":
      return (
        <Col md={6} sm={6} xs={12}>
          <CardWrap id={id} description={description} title={title} icon={icon}>
            <DownloadFile data={data} />
          </CardWrap>
        </Col>
      );
    // TODO: rename box Quarter
    case "info-box":
      return (
        <Col {...sizeProps(size)}>
          <DebugId id={id} />
          <InfoBox
            id={id}
            value={value}
            subtitle={title}
            icon={icon}
            theme={theme}
            valueType={valueType}
            chartKey={chartkey}
          />
        </Col>
      );

    case "info-box-slim":
      return (
        <Col {...sizeProps(size)} className="d-flex">
          <DebugId id={id} />
          <InfoBox
            id={id}
            layout="horizontal"
            value={value}
            subtitle={title}
            icon={icon}
            theme={theme}
            valueType={valueType}
            chartKey={chartkey}
          />
        </Col>
      );

    case "info-box-circle":
      return (
        <Col {...sizeProps(size)} className="d-flex" chartKey={chartkey}>
          <DebugId id={id} />
          <InfoBoxCircle
            id={id}
            value={value}
            total={total}
            title={title}
            icon={icon}
            theme={theme}
          />
        </Col>
      );

    case "chart-pie":
      return (
        <Col
          {...sizeProps(size)}
          style={{ display: "flex" }}
          chartKey={chartkey}
        >
          <DebugId id={id} />
          <CardWrap id={id} title={title} icon={icon}>
            <ChartPie
              id={id}
              icon={icon}
              title={title}
              theme={theme}
              chartData={chartData}
            />
          </CardWrap>
        </Col>
      );

    case "chart-donut":
      return (
        <Col {...sizeProps(size)} chartKey={chartkey}>
          <DebugId id={id} />
          <ChartDonut
            chartKey={chartkey}
            id={id}
            title={title}
            theme={theme}
            icon={icon}
            chartData={chartData}
            description={description}
          />
        </Col>
      );

    case "chart-bar":
      return (
        <Col {...sizeProps(size)}>
          <CardWrap id={id} title={title} icon={icon}>
            <ChartBar
              id={id}
              theme={theme}
              chartData={chartData}
              chartKey={chartkey}
            />
          </CardWrap>
        </Col>
      );

    case "chart-line":
      return (
        <ChartLine
          icon={icon}
          id={id}
          title={title}
          theme={theme}
          chartData={chartData}
          chartKey={chartkey}
        />
      );

    case "chart-multicircles":
      return (
        <Col xs="12" sm="12" md="12">
          <DebugId id={id} />
          <ChartMultiCircle
            chartData={chartData}
            id={id}
            title={title}
            theme={theme}
          />
        </Col>
      );

    default:
      return (
        <Col xs="12" sm="12" md="12">
          <DebugId id={id} />
          <Card>
            <CardBody>
              <h1>{props.type}</h1>
              Component not exist
            </CardBody>
          </Card>
        </Col>
      );
  }
};

export default RenderComponent;
