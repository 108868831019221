import styled from "styled-components";

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  .display {
    font-size: 22px;
    color: #4c4c4c;
    display: flex;
    align-items: center;
    .field {
      cursor: pointer;
      text-align: center;
      display: inline-block;
      padding: 3px;
      min-width: 125px;
      .fa {
        font-size: 16px;
        position: relative;
        top: -3px;
      }
      &.active {
        border-bottom: 1px solid #0060da;
        background-color: #f9f9f9;
      }
      span {
        @media screen and (max-width: 420px) {
          display: none;
        }
      }
    }
  }
`;
const Separate = styled.div`
  display: inline-block;
  margin: 0 10px;
`;
const Info = styled.div`
  padding: 10px 20px 0px 20px;
`;
const Default = styled.div`
  text-align: center;
  font-size: 13px;
  font-weight: 600;
  color: #696969;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export default { Wrapper, Info, Separate, Default };
