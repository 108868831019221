// States for First and Second
// Solved(state) is an array
// handle clicks, set states if first or second
// if second exist set both to null
// after second click check if match, if match push id to solved
// before render check solved and disable solved cards and show back side of card
// if solved length is 8 its finished

import React from "react";
import SingleCard from "../SingleCard";
import { chain as _chain, shuffle as _shuffle } from "lodash";
import iconSet from "@savewatersavemoney/iconfont/selection.json";
import Styled from "./Board.style";

const iconList = iconSet => {
  const list = [""];
  iconSet.icons.forEach(icon => {
    list.push(icon.properties.name.split(", ")[0]);
  });
  return list;
};

const icons = _shuffle(iconList(iconSet).slice(0, 8));

const board = _chain(icons)
  .concat(icons)
  .shuffle()
  .value();

const Board = props => {
  const [selection, setSelection] = React.useState([0, null]);
  const [solved, setSolved] = React.useState([0]);

  const flip = index => {
    if (selection[0] === null) {
      setSelection([index, null]);
    } else if (selection[1] === null) {
      setSelection([selection[0], index]);
    } else {
      return checkPair;
    }
  };

  const restartGame = () => {
    if (solved.length === 16) {
      setSolved([]);
    }
  };

  const checkPair = () => {
    if (selection[0] !== null && selection[1] !== null) {
      if (board[selection[0]] === board[selection[1]]) {
        setSelection([null, null]);
        setSolved([...solved, selection[0], selection[1] && selection[1]]);
      } else {
        setTimeout(() => {
          setSelection([null, null]);
        }, 1100);
      }
    }
  };

  const renderBoard = () => {
    return board.map((card, index) => {
      return (
        <SingleCard
          selection={selection}
          solved={solved}
          flip={flip}
          key={index}
          id={card}
          currentIndex={index}
          icons={card}
        />
      );
    });
  };

  const successMessage = () => {
    if (solved.length === 16) {
      return (
        <Styled.Success>
          <h3>Congratulations!</h3>
          <p>You have finished the game.</p>
          <button className="btn btn-primary btn-block" onClick={restartGame}>
            Restart
          </button>
        </Styled.Success>
      );
    }
  };

  return (
    <Styled.Wrapper>
      {checkPair()}
      {renderBoard()}
      {successMessage()}
    </Styled.Wrapper>
  );
};

export default Board;
