import styled from 'styled-components';
export const Wrapper = styled.div`
  width: 100%;
`;

export const Success = styled.div`
  position: absolute;
  top: 0;
  text-align: center;
  margin: 30% 50px;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 10px 50px 15px;
  color: white;
`;

export default {
  Wrapper,
  Success,
};
