import { logOut } from '../../actions/actionsAuth';
import { toggleDevMode } from '../../actions/actionsUi';
import { connect } from 'react-redux';
import DefaultHeader from './DefaultHeader';

// CONNECT PROPS AND DISPATCHERS
const mapStateToProps = (state, ownProps) => {
  return {
    user: state.userReducer,
    ui: state.uiReducer,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    toggleDevMode: () => dispatch(toggleDevMode()),
    logOut: () => dispatch(logOut()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DefaultHeader);
