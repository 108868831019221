import styled from "styled-components";

const ChartPie = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  @media screen and (max-width: 1200px) {
    flex-direction: column;
  }
`;

const Square = styled.div`
  position: relative;
  width: 100%;
  max-width: 300px;
  margin: auto;
  &:after {
    content: "";
    display: block;
  }
  .recharts-pie-label-line {
    display: none;
  }
  @media screen and (max-width: 1200px) {
    order: 1;
  }
`;

const Title = styled.div`
  font-size: 1.4em;
  line-height: 1.2em;
  min-height: 4.6em;
  font-weight: 500;
  text-align: center;
  padding-bottom: 20px;

  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
`;

const Icon = styled.div`
  text-align: center;
  margin-bottom: 8px;
`;

const Content = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;

  svg {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -80%);
    position: absolute;
  }
  div {
    top: 50%;
    left: 50%;
    transform: translate(-50%, 10%);
    position: absolute;
    h5 {
      margin-bottom: 0;
      color: #0b2641;
      font-weight: 600;
    }
    h4 {
      margin-bottom: 0;
      color: #0b2641;
      font-weight: 600;
    }
  }
`;

const Legend = styled.div`
  flex: 1;
  display: flex;
  @media screen and (max-width: 1200px) {
    order: 2;
  }
`;
export default { ChartPie, Title, Icon, Content, Legend, Square };
