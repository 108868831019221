import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
interface UnauthorizedProps {}

const Unauthorized: React.FC<UnauthorizedProps> = props => {
  return (
    <div className="app flex-row align-items-center">
      <Container>
        <Row className="justify-content-center">
          <Col md="6">
            <span className="clearfix">
              <h1 className="float-left display-3 mr-4">:(</h1>
              <h4 className="pt-3">
                Your credentials are fine but you have no permissions to see
                that site
              </h4>
              <p className="text-muted float-left">
                You can try <Link to={'login'}>Login</Link> with different
                account or send us an email.{' '}
              </p>
            </span>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Unauthorized;
