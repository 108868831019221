import styled from "styled-components";

const CircleProgress = styled.svg`
  width: 100%;
  height: auto;

  path {
    stroke-linecap: round;
    transition: stroke-dashoffset 0.5s ease 0s;
  }
  .trail {
    stroke: #0b2641;
  }
  text {
    fill: #25b3eb;
    font-size: 20px;
    font-weight: 600;
    dominant-baseline: middle;
    text-anchor: middle;
  }
`;

const Subtext = styled.div`
  padding-top: 10px;
  text-align: center;
  color: #787878;
`;

export default { CircleProgress, Subtext };
