import React, { Component } from "react";
import { isMetaChanged, updateIfRequired } from "../../helpers";
import {
  ErrorDisplay,
  DebugId,
  Loader,
  RenderComponent,
  NothingToDisplay
} from "../../components";

import { MetadataProps } from "../../interfaces";
import { Row, Col } from "reactstrap";
import { map as _map } from "lodash";

interface GenericPageProps {
  metadata: MetadataProps;
  getEndpointData: Function;
  genericPage: any;
  match: any;
}

class GenericPage extends Component<GenericPageProps, any> {
  componentDidMount() {
    // console.log('DID MOUNT');
    this.updateData();
  }

  componentDidUpdate(prevProps) {
    if (isMetaChanged(this.props.metadata, prevProps.metadata)) {
      // console.log('META CHANGED');
      this.updateData(true);
    }
  }

  pageId = () => {
    return this.props.match.path.replace("/", "");
  };

  updateData = (forceUpdate = false) => {
    updateIfRequired(
      this.pageId(),
      this.props.getEndpointData,
      this.props,
      forceUpdate
    );
  };

  renderLayout = layout => {
    return _map(layout, (row, index) => {
      const components = _map(row.components, component => {
        return <RenderComponent key={component.id} {...component} />;
      });

      if (row.title) {
        return (
          <Row key={index}>
            <DebugId section id={row.id} />
            <Col sm="12" md="12">
              <Row>{components}</Row>
            </Col>
          </Row>
        );
      }

      return (
        <Row key={index}>
          <DebugId section id={row.id} />
          {components}
        </Row>
      );
    });
  };

  render() {
    const pageId = this.pageId();
    const { genericPage } = this.props;
    if (!genericPage.hasOwnProperty(pageId)) return <Loader />;

    const { loading, error, done } = genericPage[pageId].status;
    const { data } = genericPage[pageId];

    if (error) return <ErrorDisplay />;
    if (loading && !done) return <Loader />;
    if (Object.keys(data).length === 0)
      //TODO: check for layout key to render templates
      return <NothingToDisplay />;

    return (
      <div className="animated fadeIn">{this.renderLayout(data.layout)}</div>
    );
  }
}

export default GenericPage;
