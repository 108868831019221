import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-xhr-backend';
// import Locize from 'i18next-locize-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
// not like to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

i18n
  // load translation using xhr -> see /public/locales
  // learn more: https://github.com/i18next/i18next-xhr-backend
  // .use(Locize)
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    load: 'currentOnly',
    fallbackLng: 'en-GB',
    // debug: true,
    // saveMissing: true,
    // backend: {
    //   projectId: process.env.REACT_APP_LOCIZE_PROJECT_ID,
    //   apiKey: process.env.REACT_APP_LOCIZE_API_KEY,
    //   referenceLng: 'en-GB',
    // },
  });

export default i18n;
