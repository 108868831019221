import React from "react";
import { CircleProgress } from "../index";
import { numberFormatter } from "../../helpers";
import { colors } from "../../assets/variables";
import { Spring } from "react-spring/renderprops.cjs";
import Styled from "./InfoBoxDetailed.styles";
export interface InfoBoxDetailedProps {
  id?: number;
  text?: any;
  theme?: string;
  total?: number;
  value?: number;
  valueText?: string;
  totalText?: string;
  dataTest?: string;
  chartKey?: string;
}

const InfoBoxDetailed = (props: InfoBoxDetailedProps) => {
  const {
    text = "Sample text",
    valueText,
    totalText,
    theme = "t1",
    total,
    chartKey,
    value
  } = props;
  function getProgressCircle() {
    if (value && total) {
      return (
        <Styled.CircleContainer>
          <Spring from={{ number: 0 }} to={{ number: value }}>
            {props => (
              <CircleProgress
                theme={theme}
                value={props.number}
                total={total}
              />
            )}
          </Spring>
        </Styled.CircleContainer>
      );
    }
  }

  return (
    <Styled.Wrapper chartKey={chartKey}>
      <Styled.Text>
        <Styled.Paragraph>{text}</Styled.Paragraph>
        <Styled.LegendWrap>
          <Styled.LegendItem>
            <Styled.LegendDot
              style={{ backgroundColor: colors[theme] }}
            ></Styled.LegendDot>
            <Styled.LegendText>{valueText}</Styled.LegendText>
            <Styled.LegendValue>{numberFormatter(value)}</Styled.LegendValue>
          </Styled.LegendItem>
          <Styled.LegendItem>
            <Styled.LegendDot
              style={{ backgroundColor: colors["c2"] }}
            ></Styled.LegendDot>
            <Styled.LegendText>{totalText}</Styled.LegendText>
            <Styled.LegendValue>{numberFormatter(total)}</Styled.LegendValue>
          </Styled.LegendItem>
        </Styled.LegendWrap>
      </Styled.Text>
      {getProgressCircle()}
    </Styled.Wrapper>
  );
};

export default InfoBoxDetailed;
