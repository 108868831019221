import React from "react";
import { DropdownItem, DropdownMenu, DropdownToggle, Nav } from "reactstrap";
import * as _ from "lodash";
import JWTDecode from "jwt-decode";
import {
  AppHeaderDropdown,
  AppNavbarBrand,
  AppSidebarToggler
} from "@coreui/react";
import { Icon } from "@savewatersavemoney/swsm-ui";
import logo from "../../assets/img/brand/www-color.svg";

interface DefaultHeaderProps {
  toggleDevMode: Function;
  user: string;
  logOut: Function;
  ui: any;
}

const DefaultHeader: React.FC<DefaultHeaderProps> = props => {
  const { user, logOut, toggleDevMode, ui } = props;

  const token = JWTDecode(_.get(user, "data.accessToken"));
  const isAdmin = _.includes(token.roles, "ROLE_ADMIN");
  return (
    <React.Fragment>
      <AppSidebarToggler className="d-lg-none" display="md" mobile />
      <AppNavbarBrand
        full={{ src: logo, width: 106, height: 40, alt: "aqKWa Logo" }}
      />
      <Nav className="ml-auto" navbar>
        <AppHeaderDropdown direction="down">
          <DropdownToggle id="user-menu-dropdown" nav>
            <Icon icon="man" size={30} />
          </DropdownToggle>
          <DropdownMenu right style={{ right: "auto" }}>
            <DropdownItem header tag="div" className="text-center">
              <strong>{_.get(user, "data.name", "Guest")}</strong>
            </DropdownItem>

            <DropdownItem
              id="user-menu-logout"
              onClick={() => {
                logOut();
              }}
            >
              <i className="fa fa-lock" /> Logout
            </DropdownItem>

            {isAdmin ? (
              <DropdownItem
                id="user-menu-logout"
                onClick={() => {
                  toggleDevMode();
                }}
              >
                <i className="fa fa-code" /> DevMode {ui.devMode ? "OFF" : "ON"}
              </DropdownItem>
            ) : (
              ""
            )}
          </DropdownMenu>
        </AppHeaderDropdown>
      </Nav>
    </React.Fragment>
  );
};

export default DefaultHeader;
