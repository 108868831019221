import React from "react";
import Styled from "./CardWrap.style";
import { Icon } from "@savewatersavemoney/swsm-ui";
import { Card, CardHeader, CardBody } from "reactstrap";

interface CardWrap {
  id: number;
  icon: string;
  title: string;
  children: any;
  description?: string;
}

const CardWrap = (props: CardWrap) => {
  const { icon, title, children, description } = props;
  return (
    <Card style={{ flex: 1 }}>
      <CardHeader>
        <Styled.TitleWrap>
          <Styled.IconWrap>
            <Icon icon={icon} size={33} />
          </Styled.IconWrap>
          <Styled.Title>{title}</Styled.Title>
        </Styled.TitleWrap>
      </CardHeader>
      <CardBody>
        {description ? <p style={{ fontSize: "16px" }}>{description}</p> : null}
        {children}
      </CardBody>
    </Card>
  );
};

export default CardWrap;
