import axios from "axios";
import history from "../history";
import { getEndpointURL } from "../helpers";

export function logIn({ email, password }, history) {
  return dispatch => {
    // console.log('dispatching');
    dispatch(logInPending());
    return axios
      .post(getEndpointURL("auth/1.0/login"), { email, password })
      .then(resp => {
        dispatch(logInFulfilled(resp));
        // console.log('AXIOS DONE');
      })
      .then(() => {
        history.push("/");
        // console.log('AXIOS PUSH /');
      })
      .catch(error => {
        dispatch(logInRejected(error.response));
        // console.log('AXIOS REJECTED response:');
        console.log(error.response);
      });
  };
}

export const logInPending = () => {
  return {
    type: "LOG_IN_PENDING"
  };
};
export const logInFulfilled = resp => {
  return {
    type: "LOG_IN_FULFILLED",
    payload: resp
  };
};
export const logInRejected = resp => {
  return {
    type: "LOG_IN_REJECTED",
    payload: resp
  };
};

export const logOut = () => {
  history.push("/login");
  return dispatch => {
    return axios
      .post(getEndpointURL("auth/1.0/logout"))
      .then(resp => {
        dispatch(clearUserdata(resp));
      })
      .catch(error => {
        dispatch(clearUserdata(error));
      });
  };
};
export const clearUserdata = resp => {
  return {
    type: "CLEAR_USERDATA",
    payload: resp
  };
};

export const refreshAccessToken = () => {
  return axios
    .post(getEndpointURL("auth/1.0/token"))
    .then(resp => {
      // console.log(resp);

      if (!resp || !resp.data || !resp.data.accessToken) {
        // console.log('!resp.data.accessToken');
        return null;
      }
      // console.log('RETURNING: ',resp.data.accessToken);
      return resp.data.accessToken;
    })
    .catch(error => {
      console.log("ERROR", error);
      return error;
    });
};
