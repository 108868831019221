export const setPickerPeriod = period => {
  return {
    type: 'SET_PICKER_PERIOD',
    period,
  };
};

export const setMeta = (
  clientSlug,
  startDate,
  endDate,
  datePickerPeriod,
  initialDate
) => {
  return {
    type: 'SET_META',
    clientSlug,
    startDate,
    endDate,
    datePickerPeriod,
    initialDate,
  };
};
