import React from 'react';
import Icon from '../../Icon/Icon';
import { includes as _includes } from 'lodash';
import logo from '../../../assets/img/brand/logo.svg';
import Styled from './SingleCard.style';
const SingleCard = props => {
  const clickCard = () => {
    if (isActive() < 2) {
      return false;
    }
    props.flip(props.currentIndex);
  };

  const isActive = () => {
    if (_includes(props.selection, props.currentIndex)) {
      return true;
    }
  };

  const isSolved = () => {
    if (_includes(props.solved, props.currentIndex)) {
      return true;
    }
  };
  return (
    <Styled.Wrapper active={isActive()} solved={isSolved()} onClick={clickCard}>
      <Styled.Flipped>
        <Styled.Front active={isActive()} solved={isSolved()}>
          <img src={logo} alt="Logo" width={'70%'} />
        </Styled.Front>
        <Styled.Back active={isActive()} solved={isSolved()}>
          <Icon icon={props.icons} color={'#006BDF'} size={'100%'} />
        </Styled.Back>
      </Styled.Flipped>
    </Styled.Wrapper>
  );
};

SingleCard.propType = {};

export default SingleCard;
