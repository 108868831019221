import React, { useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardGroup,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  FormGroup,
  FormFeedback,
  Alert
} from "reactstrap";
import { get as _get } from "lodash";

const Login = props => {
  const [inputEmail, inputEmailHandle] = useState("");
  const [inputPassword, inputPasswordHandle] = useState("");
  const [inputEmailInvalid, setInputEmailInvalid] = useState(false);
  const [inputPasswordInvalid, setInputPasswordInvalid] = useState(false);

  const login = e => {
    e.preventDefault();

    if (!inputEmail) {
      setInputEmailInvalid(true);
    } else {
      setInputEmailInvalid(false);
    }
    if (!inputPassword) {
      setInputPasswordInvalid(true);
    } else {
      setInputPasswordInvalid(false);
    }

    if (inputEmail && inputPassword) {
      // console.log('calling logIn');
      props.logIn({
        email: inputEmail,
        password: inputPassword
      });
    }
  };

  return (
    <div className="app flex-row align-items-center">
      <Container>
        <Row className="justify-content-center">
          <Col md="6">
            <CardGroup>
              <Card className="p-4">
                <CardBody>
                  <Form>
                    <h1>Login</h1>
                    <p className="text-muted">Sign In to your account</p>

                    <FormGroup>
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-user" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          data-test="login-username"
                          invalid={inputEmailInvalid}
                          onChange={e => {
                            inputEmailHandle(e.target.value);
                          }}
                          type="text"
                          placeholder="Username"
                          autoComplete="username"
                        />
                        <FormFeedback>Username can't be empty</FormFeedback>
                      </InputGroup>
                    </FormGroup>

                    <FormGroup>
                      <InputGroup className="mb-4">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-lock" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          data-test="login-password"
                          invalid={inputPasswordInvalid}
                          onChange={e => {
                            inputPasswordHandle(e.target.value);
                          }}
                          type="password"
                          placeholder="Password"
                          autoComplete="current-password"
                        />
                        <FormFeedback>Password can't be empty</FormFeedback>
                      </InputGroup>
                    </FormGroup>

                    <Button
                      data-test="login-submit"
                      type="button"
                      disabled={_get(props, "user.status.loading", false)}
                      onClick={e => {
                        login(e);
                      }}
                      color="primary"
                      className="px-4"
                    >
                      Login
                    </Button>

                    {_get(props, "user.status.error", false) && (
                      <Alert color="danger" className="mt-3">
                        Incorrect email or password
                      </Alert>
                    )}
                  </Form>
                </CardBody>
              </Card>
            </CardGroup>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Login;
