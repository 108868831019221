import React, { Component } from 'react';
import * as Sentry from '@sentry/browser';
import store from 'store2';
import { logOut } from './actions/actionsAuth';
import { connect } from 'react-redux';

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'production') {
  Sentry.init({
    release: 'test-release-1.0.0',
    dsn: process.env.REACT_APP_SENTRY_URL,
  });
}

class SentryBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error });
    store.clear();
    Sentry.withScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry.captureException(error);
    });
    // Force log-out helps to avoid error loop
    // if there is something messy in localStorage or redux store
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'production') {
      this.props.logOut();
    }
  }

  render() {
    if (this.state.error) {
      //render fallback UI
      return (
        <div>
          <button onClick={() => Sentry.showReportDialog()}>
            Report feedback
          </button>
        </div>
      );
    }
    //when there's not an error, render children untouched
    return this.props.children;
  }
}

const mapDispatchToProps = dispatch => {
  return {
    logOut: () => dispatch(logOut()),
  };
};

export default connect(
  null,
  mapDispatchToProps
)(SentryBoundary);
