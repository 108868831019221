import React from "react";
import Styled from "./Loader.styles";

const Loader = () => {
  return (
    <>
      <Styled.Wrap style={{ textAlign: "center", color: "#0b2641" }}>
        Please note, reports with a large date range may take up to 2 minutes to
        process the data.
      </Styled.Wrap>
      <Styled.Loader>
        <div />
        <div />
        <div />
        <div />
      </Styled.Loader>
    </>
  );
};

export default Loader;
