import axios from "axios";

export const getDownloadCsvData = (url, slug) => ({
  type: "GET_DOWNLOAD_CSV",
  payload: axios
    .post(`${url}`, {
      clientSlug: `${slug}`,
      responseType: "arraybuffer"
    })
    .then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      console.log(`Response:${response}`);
      console.log(
        `Response header: ${response.headers["content-disposition"]}`
      );
      const filename = response.headers["content-disposition"]
        .split("filename=")[1]
        .split(".")[0];
      console.log(`Filename is: ${filename}`);
      const link = document.createElement("a");
      link.href = url;

      link.setAttribute("download", `${filename}.csv`); //or any other extension
      document.body.appendChild(link);
      link.click();
    })
});
