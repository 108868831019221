import { connect } from 'react-redux';
import DebugId from './DebugId';

// CONNECT PROPS AND DISPATCHERS
const mapStateToProps = state => {
  return {
    devMode: state.uiReducer.devMode,
  };
};

export default connect(
  mapStateToProps,
  null
)(DebugId);
