import styled from "styled-components";

const ChartPie = styled.div`
  padding-bottom: 50px;
  max-width: 400px;
  margin: auto;
  width: 100%;
`;

const Square = styled.div`
  position: relative;
  width: 100%;
  max-width: 400px;
  margin: auto;
  &:after {
    content: "";
    display: block;
  }
  .recharts-pie-label-line {
    display: none;
  }
`;

const Title = styled.div`
  font-size: 1.4em;
  line-height: 1.2em;
  min-height: 4.6em;
  font-weight: 500;
  text-align: center;
  padding-bottom: 20px;

  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
`;

const Icon = styled.div`
  text-align: center;
  margin-bottom: 8px;
`;

const Content = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  svg {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
  }
`;

export default { ChartPie, Title, Icon, Content, Square };
