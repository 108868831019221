import React from 'react';
import './LoaderGame.styles.tsx';
import { uiReducerProps } from '../../interfaces';
import Styled from './LoaderGame.styles';

interface LoaderGameProps {
  toggleBoard?: (e?: any) => void;
  ui?: uiReducerProps;
}

const LoaderGame = (props: LoaderGameProps) => {
  const toggleHandler = () => {
    if (props.toggleBoard) {
      props.toggleBoard();
    }
  };

  const showMemoryGame = () => {
    if (props.ui) {
      return (
        <div>
          {props.ui.playButtonVisible && (
            <Styled.MemoryGameButton>
              <button
                className="btn btn-primary btn-block"
                onClick={toggleHandler}
              >
                Play game
              </button>
            </Styled.MemoryGameButton>
          )}
        </div>
      );
    }
  };

  return (
    <div>
      <Styled.Loader>
        <div />
        <div />
        <div />
        <div />
      </Styled.Loader>
      {showMemoryGame()}
    </div>
  );
};

export default LoaderGame;
