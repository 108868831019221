import { ChartDataElement } from "./../interfaces";
import { range } from "../assets/variables";
import { MetadataProps, ChartData, ChartDataTransformed } from "../interfaces";
import * as _ from "lodash";

/**
 * Comparing metadataReducer (curently selected daterange and clientslug)
 * @param current this.props
 * @param prev prevProps
 * @return Returns bool.
 **/
export function isMetaChanged(current: MetadataProps, prev: MetadataProps) {
  if (!current || !prev) {
    return false;
  }

  const { startDate, endDate, clientSlug } = current;

  const {
    startDate: p_startDate,
    endDate: p_endDate,
    clientSlug: p_clientSlug
  } = prev;

  if (
    startDate !== p_startDate ||
    endDate !== p_endDate ||
    clientSlug !== p_clientSlug
  ) {
    return true;
  }
  return false;
}

export function colorRange(index: number, colors: string[]) {
  if (colors) {
    const rangeLengthIndexed = colors.length;
    return colors[index % rangeLengthIndexed];
  }

  const rangeLengthIndexed = range.length;
  return range[index % rangeLengthIndexed];
}

/**
 * Fetching endpoint when force is true or there is no data
 * or if page metadata is different from global one (datepicker)
 * @param pageId
 * @param updaterAction Run if update.
 * @param props
 * @param force Update anyway.
 * @return If required trigger `updaterAction`.
 **/
export function updateIfRequired(
  pageId: string,
  updaterAction: Function,
  props: any,
  force: boolean = false
) {
  if (
    force ||
    !_.get(props, `genericPage.${pageId}.data`) ||
    isMetaChanged(props.metadata, _.get(props, `genericPage.${pageId}.meta`))
  ) {
    // console.log('UPDATE DATA REQUIRED');
    const { startDate, endDate, clientSlug } = props.metadata;
    updaterAction(
      {
        startDate,
        endDate,
        clientSlug
      },
      pageId
    );
  }
  return;
}

/**
 * Generating URL based on const REACT_APP_BASE_URL from .env
 * example `api/1.0/outdoor-garden`
 * @param relativeURL
 * @return Returns the accumulated value.
 **/
export function getEndpointURL(relativeURL: string) {
  return `${process.env.REACT_APP_BASE_URL}/${relativeURL}`;
}

export const chartDataTransform = (
  chartData: ChartData
): Array<ChartDataElement> => {
  if (
    chartData.hasOwnProperty("labels") &&
    Array.isArray(chartData.labels) &&
    Array.isArray(chartData.links) &&
    Array.isArray(chartData.datasets) &&
    chartData.labels.length > 0 &&
    chartData.links.length > 0 &&
    chartData.datasets.length > 0
  ) {
    const { labels } = chartData;

    return _.map(labels, (name, i) => {
      const datasets = {};
      const links = {};

      _.forEach(chartData.datasets, (set, setIndex) => {
        datasets[`dataset_${setIndex}`] = chartData.datasets[setIndex].data[i];

        links[`links_${setIndex}`] = chartData.links[i];
      });

      return {
        name,
        ...datasets,
        links
      };
    });
  } else if (
    chartData.hasOwnProperty("labels") &&
    Array.isArray(chartData.labels) &&
    Array.isArray(chartData.datasets) &&
    chartData.labels.length > 0 &&
    chartData.datasets.length > 0
  ) {
    const { labels } = chartData;

    return _.map(labels, (name, i) => {
      const datasets = {};

      _.forEach(chartData.datasets, (set, setIndex) => {
        datasets[`dataset_${setIndex}`] = chartData.datasets[setIndex].data[i];
      });

      return {
        name,
        ...datasets
      };
    });
  }
  return [];
};

export const chartDataColors = (chartData: ChartData, theme?: string) => {
  if (
    chartData.hasOwnProperty("datasets") &&
    Array.isArray(chartData.datasets) &&
    chartData.datasets.length > 0
  ) {
    const { datasets } = chartData;
    return _.map(datasets, set => {
      return _.get(set, "theme", theme); // fallback to component theme
    });
  }
  return [];
};

export const setChartBarHeight = (
  data: ChartDataTransformed,
  chartData: ChartData,
  expectedBarHeight: number = 20
): number => {
  const datasetsLength = chartData.datasets.length;
  const dataLength = data.length;
  const legendHeight = 51;
  const elementDatasetSpace =
    datasetsLength > 1 ? 14 * (datasetsLength - 1) : 0;
  const padding = 30 * dataLength + elementDatasetSpace;
  return (
    legendHeight + padding + expectedBarHeight * datasetsLength * dataLength
  );
};

export const flattenChartData = chartData => {
  const data = _.map(chartData.labels, (element, index) => {
    return {
      value: _.get(chartData, `datasets[0].data[${index}]`, 0),
      label: element
    };
  });

  return {
    total: _.sum(_.get(chartData, "datasets[0].data", 0)),
    data
  };
};
