import React from "react";
import { Row, Col, Card, CardBody } from "reactstrap";

const ErrorDisplay = () => {
  return (
    <div className="animated fadeIn">
      <Row className="justify-content-center">
        <Col md="6">
          <Card>
            <CardBody>
              <span className="clearfix">
                <span className="float-left mr-4">
                  <i className="fa text-danger display-4 fa-fire-extinguisher" />
                </span>
                <h4 className="pt-3">Error... We are working on it</h4>
                <p className="text-muted float-left mt-3">
                  Please re-run your report and contact SWSM if you receive this
                  message again.
                </p>
              </span>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default ErrorDisplay;
