import styled from 'styled-components';

const Wrapper = styled.div`
  text-align: center;
  width: 100%;
  margin-bottom: 50px;

  @media screen and (max-width: 575px) {
    margin-bottom: 30px;
  }
`;
const Title = styled.div`
  order: 1;
  margin-bottom: 10px;
  @media screen and (max-width: 575px) {
    order: 1;
  }
`;
const Subtitle = styled.div`
  color: #a5a5a5;
`;

const ProgressBar = styled.div`
  width: 50%;
  margin: auto;
  order: 2;
  @media screen and (max-width: 575px) {
    width: 50%;
    margin: auto;
    order: 3;
    z-index: 2;
    margin-bottom: 20px;
  }
`;

export default { Wrapper, Title, Subtitle, ProgressBar };
