import styled, { keyframes, css } from 'styled-components';

const solvedAnim = keyframes`
  from {
    background: #bcffcc;
  }
  to {
    background: white;
  }
`;
const frontVisible = css`
  opacity: 0;
  transition: 0.4s ease-in-out;
`;
const backVisible = css`
  opacity: 1;
  transition: 0.4s ease-in-out;
`;
const Front = styled.div`
  width: 100%;
  padding-top: 100%;
  position: relative;
  img {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
  }

  ${props => (props.solved ? frontVisible : '')}
  ${props => (props.active ? frontVisible : '')}
`;
const Back = styled.div`
  opacity: 0;
  top: 0;
  padding: 15px;
  position: absolute;
  height: 100%;

  ${props => (props.solved ? backVisible : '')}
  ${props => (props.active ? backVisible : '')}
`;

const active = css`
  transition: 1.4s ease-in-out;
`;
const solved = css`
  animation: 1s ${solvedAnim} ease-in-out;
  animation-delay: 0.4s;
`;
export const Flipped = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  @media screen and (max-width: 480px) {
    .back {
      padding: 10px;
    }
  }
`;

export const Wrapper = styled.div`
  display: inline-block;
  box-shadow: 0 0 3px;
  border-radius: 5px;
  margin-left: 5px;
  background: white;
  width: 23%;
  padding-top: 23%;
  position: relative;
  ${props => (props.active ? active : '')}
  ${props => (props.solved ? solved : '')}
`;

export default { Wrapper, Flipped, Front, Back };
